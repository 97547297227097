import React from "react"
import { compose, withProps } from "recompose"
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps"

const Map = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCo8sFLkH7VSWhMYy9bq4zEPhtGPVO29Fc&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `500px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={10} defaultCenter={{ lat: 49.44357181486503, lng: 19.942312841460804 }}>
    <Marker position={{ lat: 49.44357181486503, lng: 19.942312841460804 }} />
  </GoogleMap>
))
export default Map;